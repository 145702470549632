<template>
  <div class="container">
    <div><img class="portbg" src="../../assets/insu/13.jpg" alt="" /></div>

    <div class="main">
      <div>
        <div class="porttitle">车辆体检报告</div>
        <div class="porttime">报告时间：{{ resultInfo.date }}</div>
      </div>
      <div class="cinfo">
        <ul class="ciul">
          <li>
            <img :src="resultInfo.vehicleImg" alt="" />
          </li>
          <li>
            <div>{{ resultInfo.vehicleName }}</div>
            <div v-if="content.vin">
              VIN:{{ content.vin.slice(0, 4) }}****{{ content.vin.slice(-4) }}
            </div>
            <div v-else>VIN: -</div>
          </li>
        </ul>
      </div>
      <ul class="sore">
        <li class="sore-li">
          <div>{{ resultInfo.level }} {{ resultInfo.score }}</div>
          <div>综合评分</div>
        </li>
        <li class="sore-li">
          <div>{{ resultInfo.price }}</div>
          <div>残值</div>
        </li>
        <li class="sore-li">
          <div>{{ resultInfo.date }}</div>
          <div>基准日</div>
        </li>
      </ul>
      <div class="info">
        <div class="bkbox">
          <div class="title">过户信息概要</div>
          <ul class="ghbox">
            <li>本车共{{ content.otherInfo.transferNum }}次过户</li>
            <!-- <li>
              <div>第一次过户</div>
              <div>上海 | 2022-07-29</div>
            </li> -->
            <li>
              <div>首次登记</div>
              <div>{{ content.otherInfo.transferDate }}</div>
            </li>
          </ul>
        </div>
        <div class="bkbox">
          <div class="title">投保状态</div>
          <ul class="insul">
            <li>
              <div>交强险</div>
              <div>{{ content.otherInfo.traffic1 ? "在保" : "已过期" }}</div>
            </li>
            <li>
              <div>交强险持续同一公司</div>
              <div>
                {{ content.otherInfo.traffic2 ? "同一公司" : "非同一公司" }}
              </div>
            </li>
            <li>
              <div>交强险投保人与收益人是否一致</div>
              <div>{{ content.otherInfo.traffic3 ? "一致" : "不一致" }}</div>
            </li>
            <li>
              <div>商业险</div>
              <div>{{ content.otherInfo.business1 ? "在保" : "已过期" }}</div>
            </li>
            <li>
              <div>商业险持续同一公司</div>
              <div>
                {{ content.otherInfo.business2 ? "同一公司" : "非同一公司" }}
              </div>
            </li>
            <li>
              <div>商业险投保人与收益人是否一致</div>
              <div>{{ content.otherInfo.business3 ? "一致" : "不一致" }}</div>
            </li>
            <li>
              <div>车船险</div>
              <div>
                {{ content.otherInfo.vehicleAndVessel ? "在保" : "已过期" }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="check">
        <div class="cktitle">核心车况排查</div>
        <div class="ckinfo">
          <span>核心车况信息来自维修保养历史信息记录，其中</span>
          <img src="../../assets/mall/ms.png" alt="" />
          <span>代表未出现过维修历史，</span>
          <img src="../../assets/mall/merror.png" alt="" />
          <span>代表出现过维修历史</span>
        </div>
        <ul class="cknav">
          <li>
            <img src="../../assets/insu/1.png" alt="" />
            <div>{{ content.insurance.isState1 ? "疑似" : "非" }}火烧</div>
          </li>
          <li>
            <img src="../../assets/insu/2.png" alt="" />
            <div>{{ content.insurance.isState2 ? "疑似" : "非" }}泡水</div>
          </li>
          <li>
            <img src="../../assets/insu/3.png" alt="" />
            <div>{{ content.insurance.isState3 ? "疑似" : "非" }}盗抢</div>
          </li>
          <li>
            <img src="../../assets/insu/4.png" alt="" />
            <div>{{ content.insurance.isState4 ? "有" : "无" }}大额理赔</div>
          </li>
        </ul>

        <ul class="ckmsg">
          <li
            class="ckmsgli"
            :class="content.insurance.isState5 ? 'errActive' : ''"
          >
            <div><img src="../../assets/insu/5.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>骨架</span>
                <img
                  v-if="!content.insurance.isState5"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                包括前围骨架、A柱（左右）、后侧围（左右）、底板及加强梁、车顶、后围板、裙围、车架前横梁、前纵梁（左右）、前桥、车架中横梁、中纵梁（左右）、中后桥、车架后横梁、后纵梁（左右）
              </div>
            </div>
          </li>
          <li
            class="ckmsgli"
            :class="content.insurance.isState6 ? 'errActive' : ''"
          >
            <div><img src="../../assets/insu/6.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>发动机</span>
                <img
                  v-if="!content.insurance.isState6"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                包括曲轴、轴瓦/连杆瓦、凸轮轴、连杆/连杆螺丝、止推片、气门油封、缸体/缸盖、油底壳
              </div>
            </div>
          </li>
          <li
            class="ckmsgli"
            :class="content.insurance.isState7 ? 'errActive' : ''"
          >
            <div class="ckmsgdelimg">
              <img src="../../assets/insu/7.png" alt="" />
            </div>
            <div class="ckmsgdel">
              <div>
                <span>变速器</span>
                <img
                  v-if="!content.insurance.isState7"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                一轴/一轴轴承/一轴锁止卡环、二轴/二轴轴承/二轴锁止卡环、换挡执行机构
              </div>
            </div>
          </li>
          <li
            class="ckmsgli"
            :class="content.insurance.isState8 ? 'errActive' : ''"
          >
            <div><img src="../../assets/insu/8.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>安全带</span>
                <img
                  v-if="!content.insurance.isState8"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>主副驾驶安全带、上下卧铺安全带及对应卡扣</div>
            </div>
          </li>
          <li
            class="ckmsgli"
            :class="content.insurance.isState9 ? 'errActive' : ''"
          >
            <div><img src="../../assets/insu/9.png" alt="" /></div>
            <div class="ckmsgdel">
              <div>
                <span>安全气囊</span>
                <img
                  v-if="!content.insurance.isState9"
                  src="../../assets/mall/ms.png"
                  alt=""
                />
                <img v-else src="../../assets/mall/merror.png" alt="" />
              </div>
              <div>
                主气囊、副气囊、气帘、侧气囊、气囊电脑/气囊控制模块、气囊线束/气囊插头
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="errmsg">
        <div class="cktitle">外观损失概览</div>
        <div class="errtiptxt">
          历史损失信息来自于维修保养历史信息，而非当前代表车况有异常，信息内容仅作参考
        </div>
        <ul class="errcar" v-if="content.insurance.lossDetail">
          <li>
            <div class="carbox">
              <img class="carImg" src="../../assets/insu/10.jpg" alt="" />
              <div
                class="carl1"
                :class="errIndex === 1 ? 'err_border' : ''"
                @click="onClickAppearance(1)"
              >
                <div>{{ content.insurance.lossDetail[1].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[1].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2"
                :class="errIndex === 0 ? 'err_border' : ''"
                @click="onClickAppearance(0)"
              >
                <div>{{ content.insurance.lossDetail[0].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[0].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3"
                :class="errIndex === 2 ? 'err_border' : ''"
                @click="onClickAppearance(2)"
              >
                <div>{{ content.insurance.lossDetail[2].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[2].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl4"
                :class="errIndex === 3 ? 'err_border' : ''"
                @click="onClickAppearance(3)"
              >
                <div>{{ content.insurance.lossDetail[3].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[3].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4"
                :class="errIndex === 8 ? 'err_border' : ''"
                @click="onClickAppearance(8)"
              >
                <div>{{ content.insurance.lossDetail[8].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[8].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3 carl4"
                :class="errIndex === 4 ? 'err_border' : ''"
                @click="onClickAppearance(4)"
              >
                <div>{{ content.insurance.lossDetail[4].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[4].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4 carl5"
                :class="errIndex === 9 ? 'err_border' : ''"
                @click="onClickAppearance(9)"
              >
                <div>{{ content.insurance.lossDetail[9].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[9].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl4 carl6"
                :class="errIndex === 5 ? 'err_border' : ''"
                @click="onClickAppearance(5)"
              >
                <div>{{ content.insurance.lossDetail[5].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[5].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl2 carl4 carl6"
                :class="errIndex === 7 ? 'err_border' : ''"
                @click="onClickAppearance(7)"
              >
                <div>{{ content.insurance.lossDetail[7].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[7].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
              <div
                class="carl1 carl3 carl4 carl6"
                :class="errIndex === 6 ? 'err_border' : ''"
                @click="onClickAppearance(6)"
              >
                <div>{{ content.insurance.lossDetail[6].name }}</div>
                <img
                  v-if="content.insurance.lossDetail[6].state == 'true'"
                  style="margin: 0"
                  src="../../assets/insu/16.png"
                  alt=""
                />
                <img v-else src="../../assets/insu/12.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="warpper_err" v-if="errIndex !== null">
              <div class="title">{{ errMsg.value }}</div>
              <!-- <div class="tips">现场还原(基于理赔记录推测)</div> -->
              <div class="solid"></div>
              <div class="text">
                碰撞程度:<span style="font-weight: bold">{{
                  errMsg.value
                }}</span>
              </div>
              <div class="text">
                碰撞速度:<span style="font-weight: bold">{{
                  errMsg.speed
                }}</span>
              </div>
              <div class="text">
                车辆损失:<span style="font-weight: bold">{{
                  errMsg.loss
                }}</span>
              </div>
            </div>

            <img v-else src="../../assets/insu/11.png" alt="" />
          </li>
        </ul>
      </div>
      <div class="bkbox" v-if="content.illegal.length > 0">
        <div class="title">违法记录</div>
        <ul
          class="bkul"
          v-for="(item, index) in content.illegal"
          @click="onClickTheLaw(item)"
          :key="index"
        >
          <li>
            <div>{{ item.companyName }}</div>
            <div class="bktxt">{{ item.plateNo }}</div>
          </li>
          <li>{{ item.punishResult }}</li>
          <li>{{ item.punishDate }}</li>
        </ul>
      </div>
      <div class="top">
        <div class="toptitle">行驶概况</div>
        <ul class="xingshiUl">
          <li class="xsli">
            <div>轨迹完整率</div>
            <div v-if="content.truck.survey1">
              {{ content.truck.survey1 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>平均速度</div>
            <div v-if="content.truck.survey2">
              {{ content.truck.survey2
              }}<span style="font-size: 12px">km/h</span>
            </div>
          </li>
          <li class="xsli">
            <div>日均运营时长</div>
            <div v-if="content.truck.survey3">
              {{ content.truck.survey3
              }}<span style="font-size: 12px">小时</span>
            </div>
          </li>
          <li class="xsli">
            <div>夜间行驶里程占比</div>
            <div v-if="content.truck.survey4">
              {{ content.truck.survey4 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>高速公路行驶里程占比</div>
            <div v-if="content.truck.survey5">
              {{ content.truck.survey5 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>超速行驶里程占比</div>
            <div v-if="content.truck.survey6">
              {{ content.truck.survey6 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>严重超速行驶时长占比</div>
            <div v-if="content.truck.survey7">
              {{ content.truck.survey7 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>车辆载重</div>
            <div v-if="content.truck.survey8">
              {{ content.truck.survey8 }}<span style="font-size: 12px">吨</span>
            </div>
          </li>
          <li class="xsli">
            <div>第一长跑路线次数占比</div>
            <div v-if="content.truck.survey9">
              {{ content.truck.survey9 }}<span style="font-size: 12px">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="dingwei top">
        <div class="dwtop">
          <ul class="dwt">
            <li>车辆定位</li>
            <li>{{ content.truck.location1 }}</li>
          </ul>
          <div class="dws">上报时间：{{ content.truck.location2 }}</div>
        </div>
        <div class="dwimg">
          <img :src="content.truck.location3" alt="" />
        </div>
        <ul class="dwinfo">
          <li class="dwil">
            <div>{{ content.truck.location4 }}</div>
            <div>车速</div>
          </li>
          <li class="dwil">
            <div>{{ content.truck.location5 }}</div>
            <div>方向</div>
          </li>
          <li class="dwil">
            <div>{{ content.truck.location6 }}</div>
            <div>总里程</div>
          </li>
        </ul>
        <ul class="dwtxt">
          <li><img src="../../assets/iconLocation.png" alt="" /></li>
          <li>{{ content.truck.location7 }}</li>
        </ul>
      </div>
      <div class="err top">
        <div class="etop">车辆风险</div>
        <ul class="errul">
          <li class="errli" :class="{ basic: errKey == 1 }" @click="showErr(1)">
            驾驶风险
          </li>
          <li class="errli" :class="{ basic: errKey == 2 }" @click="showErr(2)">
            运营风险
          </li>
          <li class="errli" :class="{ basic: errKey == 3 }" @click="showErr(3)">
            道路风险
          </li>
        </ul>
        <ul class="errlev">
          <li class="errlevli">
            <div class="eta">A</div>
            <div class="errA"></div>
            <div>优秀</div>
          </li>
          <li class="errlevli">
            <div class="etb">B</div>
            <div class="errB"></div>
            <div>良好</div>
          </li>
          <li class="errlevli">
            <div class="etc">C</div>
            <div class="errC"></div>
            <div>正常</div>
          </li>
          <li class="errlevli">
            <div class="etd">D</div>
            <div class="errD"></div>
            <div>轻微</div>
          </li>
          <li class="errlevli">
            <div class="ete">E</div>
            <div class="errE"></div>
            <div>危险</div>
          </li>
          <li class="errlevli">
            <div class="etf">F</div>
            <div class="errF"></div>
            <div>严重</div>
          </li>
        </ul>
        <ul class="errinfo">
          <li class="errioli" v-for="(item, index) in errlist" :key="index">
            <div>{{ item.name }}</div>
            <div v-if="item.key == 'A'" class="eta">{{ item.key }}</div>
            <div v-if="item.key == 'B'" class="etb">{{ item.key }}</div>
            <div v-if="item.key == 'C'" class="etc">{{ item.key }}</div>
            <div v-if="item.key == 'D'" class="etd">{{ item.key }}</div>
            <div v-if="item.key == 'E'" class="ete">{{ item.key }}</div>
            <div v-if="item.key == 'F'" class="etf">{{ item.key }}</div>
            <div v-if="item.key == 'A'" class="errA errAs errall">
              <img class="era" src="../../assets/insu/1-1.png" alt="" />
            </div>
            <div v-if="item.key == 'B'" class="errB errBs errall">
              <img class="era" src="../../assets/insu/1-2.png" alt="" />
            </div>
            <div v-if="item.key == 'C'" class="errC errCs errall">
              <img class="era" src="../../assets/insu/1-3.png" alt="" />
            </div>
            <div v-if="item.key == 'D'" class="errD errDs errall">
              <img class="era" src="../../assets/insu/1-4.png" alt="" />
            </div>
            <div v-if="item.key == 'E'" class="errE errEs errall">
              <img class="era" src="../../assets/insu/1-5.png" alt="" />
            </div>
            <div v-if="item.key == 'F'" class="errF errFs errall">
              <img class="era" src="../../assets/insu/1-6.png" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div class="errtips">
        <div class="errtstop">
          <div>风险建议</div>
          <div>
            保持良好的驾驶习惯，转弯时的车速不能超过40公里/时要定期检查轮胎、刹车装置是否正常，保证车辆车况属于正常状态。
          </div>
        </div>
        <div class="errtstop">
          <div>免责说明</div>
          <div>此报告数据仅供于改善驾驶行为，提高商用车营运安全使用。</div>
        </div>
      </div>
      <div class="wbhistory check">
        <div class="cktitle">维保记录</div>
        <div class="wbbox">
          <div
            class="wxboxlist"
            v-for="(item, index) in content.insurance.records"
            :key="index"
          >
            <div class="wbtitle">维保记录</div>
            <ul class="wbul">
              <li>材料</li>
              <li>{{ item.material }}</li>
            </ul>
            <ul class="wbul">
              <li>维保里程</li>
              <li>{{ item.mileage }}</li>
            </ul>
            <ul class="wbul">
              <li>维保日期</li>
              <li>{{ item.date }}</li>
            </ul>
            <ul class="wbul">
              <li>维保项目详情</li>
              <li>{{ item.detail }}</li>
            </ul>
            <ul class="wbul">
              <li>维保类型</li>
              <li>{{ item.type }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <ul class="report">
        <li>报告编号：</li>
        <li>2020102381092SHGF10283</li>
      </ul>
      <ul class="report">
        <li>订单编号：</li>
        <li>202010238109210283</li>
      </ul> -->
      <div class="retxt">
        免责声明:报告内容旨在帮助您组织和管理有关二手商用车的历史信息，本公司提供该报告的目的并非在于向您提供任何法律、税务或财务建议。因此本服务仅限于二手商用车交易/评估主体为了解交易车辆保险情况的目的使用商用车大数据平台保险查询服务。详细条款请参阅《商用车大数据平台用户服务协议》。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errIndex: null,
      content: {},
      resultInfo: {},
      errKey: 1,
      errlist: [],
    };
  },
  created() {
    this.getList();
  },
  mouted() {},
  methods: {
    showErr(key) {
      this.errKey = key;
      if (key == 1) this.errlist = this.content.truck.risk1;
      if (key == 2) this.errlist = this.content.truck.risk2;
      if (key == 3) this.errlist = this.content.truck.risk3;
    },

    onClickAppearance(index) {
      let content = this.content;
      if (content.insurance.lossDetail[index].state == "true") {
        console.log(content.insurance.lossDetail[index]);
        this.errMsg = content.insurance.lossDetail[index];
        this.errIndex = index - 0;
      } else {
        this.errMsg = {};
        this.errIndex = null;
      }
    },
    onClickTheLaw(data) {
      this.$router.push("/insurance/traffic/v1?id=" + data.id);
    },
    getList() {
      let str = localStorage.getItem("objMsgInfo");
      let str1 = localStorage.getItem("resultInfo");
      let objMsgInfo = JSON.parse(str);
      objMsgInfo.otherInfo = JSON.parse(objMsgInfo.otherInfo);
      objMsgInfo.insurance = JSON.parse(objMsgInfo.insurance);
      objMsgInfo.illegal = JSON.parse(objMsgInfo.illegal);
      objMsgInfo.truck = JSON.parse(objMsgInfo.truck);

      this.errlist = objMsgInfo.truck.risk1;
      let resultInfo = JSON.parse(str1);
      console.log(objMsgInfo);
      console.log(resultInfo);
      this.content = objMsgInfo;
      this.resultInfo = resultInfo;
    },
  },
};
</script>
<style scoped>
.container {
  background: #dfe6ee;
  /* padding: 20px 0; */
  padding-bottom: 30px;
  min-height: 100vh;
}
.portbg {
  width: 100%;
  height: 300px;
}
.porttitle {
  font-size: 26px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 2px;
}
.porttime {
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  margin-top: 10px;
}

.main {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
  top: 35px;
}
.sore {
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 14px;
  justify-content: space-between;
  text-align: center;
}
.sore-li > div:nth-of-type(2) {
  color: #999999;
  margin-top: 8px;
}
.cinfo {
  box-sizing: border-box;
  padding: 4px;
}
.ciul {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}
.ciul img {
  width: 180px;
  height: 100px;
  border-radius: 4px;
  margin-right: 10px;
}
.title {
  font-size: 22px;
  font-weight: bold;
}
.bkbox {
  font-size: 14px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}
.ghbox {
  border: 1px solid #eeeeee;
  margin-top: 10px;
  border-radius: 4px;
}
.ghbox > li {
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.ghbox > li:nth-of-type(1) {
  background: #f7f7fb;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
}
.insul {
  border: 1px solid #eeeeee;
  margin-top: 10px;
  border-radius: 4px;
  background: #f7f7fb;
  border-top: none;
}
.insul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #eeeeee;
}
.check,
.errmsg {
  font-size: 14px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  padding-top: 20px;
  border-radius: 4px;
}

.cktitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.ckinfo {
  line-height: 20px;
}
.ckinfo img {
  width: 12px;
  height: 12px;
  padding-bottom: 4px;
}
.cknav {
  display: flex;
  font-size: 14px;
  text-align: center;
  justify-content: space-between;
  background: #f6f7fb;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
}
.cknav img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.ckmsg {
  box-sizing: border-box;
}
.ckmsg > li:nth-of-type(1) {
  margin: 0;
}
.ckmsgli {
  padding: 10px;
  background: #f6f7fb;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 10px;
}
.ckmsgli > div:nth-of-type(1) {
  width: 90px;
  height: 68px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 10px;
}
.ckmsg img {
  width: 80px;
  height: 58px;
}
.ckmsgdel img {
  width: 12px;
  height: 12px;
  padding-bottom: 4px;
}
.ckmsgdel > div:nth-of-type(2) {
  font-size: 10px;
  color: #333333;
  margin-top: 4px;
  line-height: 12px;
}
.ckmsgdelimg img {
  width: 80px;
  height: 38px;
}
.ckmsgdel span {
  margin-right: 4px;
}
.errmsg {
  margin: 10px 0;
}
.errtiptxt {
  line-height: 20px;
}
.carImg {
  width: 120px;
  height: 250px;
}
.errcar {
  display: flex;
  padding: 10px 0;
  /* align-items: center; */
}
.errcar > li:nth-of-type(1) {
  margin-right: 10px;
  margin-left: 10px;
}
.carbox {
  position: relative;
}
.carl1 {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  box-sizing: border-box;
  background: #ffffff;
  position: absolute;
  left: 6px;
  top: 10px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
}
.carl1 > div:nth-of-type(1) {
  padding-top: 10px;
}
.carl1 img {
  width: 10px;
  height: 10px;
  margin-top: 4px;
}
.carl2 {
  left: 45px;
  top: 0px;
}
.carl3 {
  left: 86px;
}
.carl4 {
  top: 60px;
}
.carl5 {
  top: 114px;
}
.carl6 {
  top: 210px;
}
.bkbox {
  font-size: 14px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}
.bkul {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 12px;
}
.bkul > li:nth-of-type(1) {
  display: flex;
  align-content: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 8px;
}
.bktxt {
  color: #2d6af6;
  margin-left: 10px;
}
.bkul > li:nth-of-type(2) {
  margin: 10px 0;
}
.bkul > li:nth-of-type(3) {
  color: #999999;
}
.top {
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
}
.xingshiUl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #666666;
}
.toptitle {
  font-size: 22px;
  font-weight: bold;
}
.xsli {
  width: 30%;
  font-size: 16px;
  background: #e9f4fd;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
}
.xsli > div:nth-of-type(1) {
  height: 30px;
  font-size: 12px;
}
.xsli > div:nth-of-type(2) {
  color: #2d6af6;
}
.dingwei {
  margin: 10px 0;
}
.dwtop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dwt {
  display: flex;
  align-items: center;
}
.dwt > li:nth-of-type(1) {
  font-size: 18px;
  font-weight: bold;
}
.dwt > li:nth-of-type(2) {
  font-size: 14px;
  background: #4e6bea;
  padding: 2px 5px;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 10px;
}
.dws {
  font-size: 12px;
  color: #999999;
}
.dwimg {
  width: 100%;
  margin: 10px 0;
}
.dwimg img {
  width: 100%;
}
.dwinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dwil {
  width: 32%;
  font-size: 10px;
  background: #f4f9fe;
  text-align: center;
  padding: 10px 0;
}
.dwil > div:nth-of-type(1) {
  margin-bottom: 5px;
  font-size: 12px;
}
.dwtxt {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-top: 20px;
}
.dwtxt > li:nth-of-type(1) {
  margin-right: 4px;
}
.etop {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.errul {
  display: flex;
  font-size: 14px;
  border: 1px solid #999999;
  border-left: none;
  justify-content: space-between;
}
.errul > li {
  padding: 10px 0;
  flex: 1;
  text-align: center;
  border-left: 1px solid #999999;
}
.basic {
  background: #2d6af6;
  color: #ffffff;
}
.errlev {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
}
.errA {
  width: 30px;
  border: 1px solid #82cb83;
}
.eta {
  color: #82cb83;
}
.errB {
  width: 30px;
  border: 1px solid #8dd3da;
}
.etb {
  color: #8dd3da;
}
.errC {
  width: 30px;
  border: 1px solid #617fe3;
}
.etc {
  color: #617fe3;
}
.errD {
  width: 30px;
  border: 1px solid #ecc665;
}
.etd {
  color: #ecc665;
}
.errE {
  width: 30px;
  border: 1px solid #db8747;
}
.ete {
  color: #db8747;
}
.errF {
  width: 30px;
  border: 1px solid #d75548;
}
.etf {
  color: #d75548;
}
.errlevli > div:nth-of-type(2) {
  margin: 10px 0;
}
.errioli {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  align-items: center;
}
.errioli > div:nth-of-type(1) {
  width: 92px;
  text-align: right;
}
.errioli > div:nth-of-type(2) {
  margin: 0 10px;
}
.errall {
  height: 0px;
  position: relative;
}
.era {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -24px;
  right: 0px;
}
.errAs {
  width: 50px;
}
.errBs {
  width: 70px;
}
.errCs {
  width: 110px;
}
.errDs {
  width: 145px;
}
.errEs {
  width: 180px;
}
.errFs {
  width: 205px;
}
.errtstop {
  background: #f4f9fe;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.errtstop > div:nth-of-type(1) {
  color: #2d6af6;
  font-size: 20px;
  margin-bottom: 10px;
}
.errtstop > div:nth-of-type(2) {
  line-height: 18px;
}
.wbul {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.wbtitle {
  text-align: center;
  color: #2d6af6;
  margin-bottom: 10px;
}
.wxboxlist > ul:nth-of-type(1) {
  border-top: 1px solid #cccccc;
}
.wbul {
  border: 1px solid #cccccc;
  border-top: none;
}
.wbul > li {
  padding: 10px;
}
.wbul > li:nth-of-type(1) {
  width: 90px;
}
.wbul > li:nth-of-type(2) {
  flex: 1;
  border-left: 1px solid #cccccc;
  line-height: 20px;
}
.wxboxlist,
.wbhistory {
  margin-top: 10px;
}
.report {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
  color: #333333;
}
.retxt {
  font-size: 14px;
  margin: 10px 0;
  line-height: 20px;
}
.errActive {
  background: #fef7e8 !important;
}
.err_border {
  border: 1px solid green;
}

.warpper_err {
  flex: 1;
  /* height: 200px; */
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 10px;
  background: linear-gradient(180deg, #eaeef6 0%, #fdfdfe 26%, #fdfdfe 100%);
}
.warpper_err > .title {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
.warpper_err > .tips {
  font-size: 12px;
  color: #757575;
  margin-top: 8px;
}
.warpper_err > .solid {
  width: 100%;
  height: 0px;
  border-bottom: 1px solid #e0e0e0;
  margin: 8px 0;
}
.warpper_err > .text {
  font-size: 13px;
  color: #333333;
  margin-top: 10px;
}
</style>
